<template>
  <div class="game-board-container">
    <div class="sentence">{{ sentence }}</div>
    <section class="game-board">
      <SegmentView @swapEvent="swapped" @selectedToMoveEvent="selectedToMove" v-for="(seg, index) in segments"
                   :key=seg
                   :index=index
                   :segment=seg
      />
    </section>
    <div class="menu">


      <div class="menu-item-label">score</div>
      <div class="menu-item">
        {{ score }}
      </div>

      <div class="menu-item-label">cur</div>
      <div class="menu-item">
        {{ currentChallenge+1 }}/{{amountFixed}}
      </div>

      <div class="menu-item-label">sec</div>
      <div class="menu-item">
        <TimerView v-if="state=='play'" @timeIsUp="timeIsUp" :secs=startSeconds :key="sentence"></TimerView>
      </div>


      <div v-if="state==='review'" class="menu-item-button" role="button" @click="back()">
        <img :src="backSvg" alt="back" height="17px"/>
      </div>
      <div v-else class="menu-item-label">
      </div>

      <div class="menu-item-button" role="button" @click="next()">
        <img :src="nextSvg" alt="next"
             height="17px"/>
      </div>


    </div>
    <!-- Review Menu -->
    <div v-if="state==='review'" class="menu">

      <div class="menu-item-label">sol</div>
      <section v-if="isLoaded" class="game-board-solutions" @mouseleave="notShow(solutions)"
               @mouseover="show(solutions)">
        <SegmentView v-for="(seg, index) in solutions"
                     :key=seg
                     :index=index
                     :segment=seg
        />
      </section>

      <div class="menu-item-label">ans</div>
      <section v-if="isLoaded" class="game-board-solutions" @mouseleave="notShow(swapAnswers[currentChallenge])"
               @mouseover="show(swapAnswers[currentChallenge])">
        <SegmentView v-for="(seg, index) in swapAnswers[currentChallenge]"
                     :key=seg
                     :index=index
                     :segment=seg
        />
      </section>

      <div class="menu-item-label"></div>

      <div class="menu-item-label"></div>

      <div class="menu-item-label"></div>

      <div class="menu-item-button" role="button" @click="home()">

        <img :src="homeSvg" alt="home"
             height="17px"/>
      </div>

    </div>
    <br/>
  </div>

</template>

<script setup>
import SegmentView from "@/components/SegmentView";
import TimerView from "@/components/TimerView";
import axios from 'axios';
import {onMounted, ref} from "vue";
import backSvg from '@/assets/back.svg';
import nextSvg from '@/assets/next.svg';
import homeSvg from '@/assets/home.svg';
import {useRoute, useRouter} from "vue-router";
import json from '@/assets/tarskiworld.json';

const TESTING = false;

const API_MACHINE = "http://localhost:1969";
let segments = ref([]);
let sentence = ref("Loading...");
let isLoaded = ref(false);
let startSeconds = ref(null);
let tarskiWorld = ref(null);
let score = ref(0);
let solutions = ref([]);
let swapAnswers = [];
let challengeList = ref([]);
let currentChallenge = ref(0);
let time = ref();
let state = ref("play");
let challengeAmount = 0;
let amountFixed = ref(0);

const router = useRouter();

//TODO finish the game
// 1. Add given answers
// 2. Social login
// 3. Come up with score:
// Click and click other segment to move: not drag and drop
//  penalty for skipping,
//  penalty for wrong answers,
//  advantage for fast correct answers
// 4. About page
// 5. Tutorial
// 6. Leaderboard
// 7. Share your results on twitter, invite somebody
// 9. Save generated challenges in database.
//10. Flag a challenge.
//11. Google analytics


onMounted(async () => {
  const route = useRoute();
  await router.isReady();
  startSeconds.value = Number.parseInt(route.query.time);
  time.value = route.query.time;
  const challengeType = route.query.challengeType;
  const complexity = route.query.complexity;
  challengeAmount = route.query.challengeAmount;

  let challenges = await getChallenges(challengeType, challengeAmount, complexity);
  if (challenges) {
    segments.value = challenges[currentChallenge.value].challengeUniverse.segments;
    sentence.value = challenges[currentChallenge.value].sentences[0].value;
    isLoaded.value = true;
    tarskiWorld.value = challenges[currentChallenge.value];
    solutions.value = challenges[currentChallenge.value].solutions;
    challengeList.value = challenges;
    amountFixed.value = Number.parseInt(challengeAmount);
  }
})

function back() {
  const INDEX_START = 0;
  if (currentChallenge.value > INDEX_START) {
    currentChallenge.value--;
    segments.value = challengeList.value[currentChallenge.value].challengeUniverse.segments;
    sentence.value = challengeList.value[currentChallenge.value].sentences[0].value;
    isLoaded.value = true;
    tarskiWorld.value = challengeList.value[currentChallenge.value];
    solutions.value = challengeList.value[currentChallenge.value].solutions;
  }
}

function next() {
  const INDEX_END = challengeAmount - 1;
  if (currentChallenge.value < INDEX_END) {
    currentChallenge.value++;
    startSeconds.value = Number.parseInt(time.value);
    segments.value = challengeList.value[currentChallenge.value].challengeUniverse.segments;
    sentence.value = challengeList.value[currentChallenge.value].sentences[0].value;
    isLoaded.value = true;
    tarskiWorld.value = challengeList.value[currentChallenge.value];
    solutions.value = challengeList.value[currentChallenge.value].solutions;
  } else {
    state.value = "review";
    time.value = 0;
  }
}

function timeIsUp() {
  sentence.value = "Time is up...";
  time.value = 0;
  //segments.value = [];
  startSeconds.value = 0;
  state.value = "review";
}

function home() {
  router.push({name: 'home'});
}

let previousSelectedSegmentId = -1;

function atLeastOneSegmentHasShape(curr, prev) {

  let c = curr.querySelector(".svg-container");
  let p = prev.querySelector(".svg-container");

  return p || c;
}

function moveInGui(curr, prev) {
  const USE = true;
  if (USE) {
    return;
  }

  const cloneCurr = curr.cloneNode(true);
  const clonePrev = prev.cloneNode(true);

  //empty prev
  while (prev.firstChild) {
    prev.firstChild.remove();
  }
  prev.appendChild(cloneCurr);

  while (curr.firstChild) {
    curr.firstChild.remove();
  }
  curr.appendChild(clonePrev);
}

function selectedToMove(currentSelectedId) {
  let prev = document.getElementById(previousSelectedSegmentId);

  if (previousSelectedSegmentId == -1) {
    prev = document.getElementById(currentSelectedId);
    previousSelectedSegmentId = currentSelectedId;
    prev.className = "draggable";
    return;
  } else if (previousSelectedSegmentId == currentSelectedId) {
    //deselected!
    previousSelectedSegmentId = -1;
    prev.className = "notDraggable";
    return;
  }

  let curr = document.getElementById(currentSelectedId);

  if (!atLeastOneSegmentHasShape(curr, prev)) {
    curr.className = "notDraggable";
    prev.className = "notDraggable";
    previousSelectedSegmentId = -1;
    return;
  }
  moveInGui(curr, prev);

  curr.className = "draggable";
  prev.className = "draggable";

  const pair = {}
  pair.firstId = Number(previousSelectedSegmentId);
  pair.secondId = Number(currentSelectedId);

  previousSelectedSegmentId = -1;

  let delayInMilliseconds = 0.4; //0.75 second

  setTimeout(function () {
    swapped(pair);
  }, delayInMilliseconds);

}

async function swapped(pair) {
  const solution = JSON.parse(JSON.stringify(segments.value));

  if (pair.firstId === pair.secondId) {
    return; //can not swap identical segments
  }

  const firstSegment = solution[pair.firstId];
  const firstCol = firstSegment.col;
  const firstRow = firstSegment.row;
  const secondSegment = solution[pair.secondId];
  const secondCol = secondSegment.col;
  const secondRow = secondSegment.row;

  // update the first segment A(1,1) = B(2,2)
  // but segment A just needs to be B and the new "A" should maintain position 1,1
  solution[pair.firstId] = secondSegment;
  //but do not overwrite its position
  solution[pair.firstId].row = firstRow; // do not update position
  solution[pair.firstId].col = firstCol; // do not update position

  // update the second segment
  solution[pair.secondId] = firstSegment;
  //but do not overwrite its position
  solution[pair.secondId].row = secondRow; // do not update position
  solution[pair.secondId].col = secondCol; // do not update position

  //store the answers for the review state
  const answersSwaps = [];
  answersSwaps.push(firstSegment);
  answersSwaps.push(secondSegment);
  swapAnswers[currentChallenge.value] = answersSwaps;


  const challenge = JSON.parse(JSON.stringify(tarskiWorld.value));
  challenge.challengeUniverse.segments = solution;

  let evalResult = await postData(`${API_MACHINE}/universe/evaluate`, challenge);

  if (evalResult) {
    let correct = evalResult[0];
    if (correct) {
      score.value++;
    } else {
      score.value--;
    }
  }
  await next();
}

function show(solutions) {
  //TODO add or remove classes instead of assign color
  document.getElementById(solutions[0].index).style.backgroundColor = "#AAA";
  document.getElementById(solutions[1].index).style.backgroundColor = "#AAA";
}

function notShow(solutions) {
  //TODO add or remove classes instead of assign color
  document.getElementById(solutions[0].index).style.backgroundColor = "#202020";
  document.getElementById(solutions[1].index).style.backgroundColor = "#202020";
}

async function getChallenges(challengeType, challengeAmount, complexity) {

  const url = `${API_MACHINE}/challenge?type=${challengeType}&amount=${challengeAmount}&complexity=${complexity}`;

  if (TESTING) {
    return json;
  }

  try {
    const response = await axios.get(url);
    return response.data;
  } catch (err) {
    console.log(err);
  }
}


async function postData(url = '', data = {}) {
  const promise = axios.post(url, data);
  const res = promise.then(response => response.data);
  promise.catch(error => {
        alert(error);
      }
  );
  return res;
}


</script>

<style>


</style>
