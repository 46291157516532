<script setup>

import {onMounted, ref} from "vue";
import ToolTip from "@/components/parts/ToolTip.vue";


const props = defineProps(['elementMap'])

let explanation = ref("");
let element = ref("");

onMounted(() => {
  explanation.value = props.elementMap.explanation;
  element.value = props.elementMap.element;
});

let isTooltipVisible = ref(false);
let tooltipPosition = ref({x: 0, y: 0});


const showTooltip = () => {
  isTooltipVisible.value = true;
};

const hideTooltip = () => {
  isTooltipVisible.value = false;
};

const updateTooltipPosition = (event) => {
  tooltipPosition.value = {x: event.clientX - 50, y: event.clientY + 30}; // Adjust the offset as needed
};

</script>

<template>
  <div style="display: inline">
    <span v-if="explanation === ' ' || explanation === ','">
      <span>{{ element }}</span>
    </span>
  <span v-else @mouseenter="showTooltip" @mouseleave="hideTooltip" @mousemove="updateTooltipPosition">
      <span class="hover-text">{{ element }}</span>
    </span>
  <tool-tip :position="tooltipPosition" :text="explanation" :visible="isTooltipVisible"/>
  </div>
</template>

<style scoped>
.hover-text {
  cursor: pointer;
}

.hover-text:hover {
  color: #999;
}
</style>
