import axios from "axios";

axios.defaults.baseURL = process.env.VUE_APP_API_URL;

export async function getRandomSentence() {
    let rowAmount = getCSSVariable("--rowAmount");
    let colAmount = getCSSVariable("--colAmount");
    const url = `/universe/random?rowAmount=${rowAmount}&colAmount=${colAmount}`;
    try {
        const response = await axios.get(url);
        return response.data?.sentences[0]?.value;

    } catch (err) {
        console.log(err);
    }
}

export async function getChallenges(searchTerm) {
    let rowAmount = getCSSVariable("--rowAmount");
    let colAmount = getCSSVariable("--colAmount");
    const url = `/explore?sentence=${searchTerm}&rowAmount=${rowAmount}&colAmount=${colAmount}&graph=true`;

    try {
        const response = await axios.get(url);
        return response.data;
    } catch (err) {
        console.log(err);
    }
}

export async function getGraph(searchTerm) {
    const url = `/explore/${searchTerm}`;
    try {
        const response = await axios.get(url);
        return response.data;
    } catch (err) {
        console.log(err);
    }
}

export function getCSSVariable(varName) {
    return getComputedStyle(document.documentElement)
        .getPropertyValue(varName);
}

const px = "px";
const MIN_CELL_SIZE = 50;
const MAX_CELL_SIZE = 120;
function updateCellSize() {

    let value = MAX_CELL_SIZE;
    const SCREEN_CUT_OFF_WIDTH = 568;
    if (screen.availWidth <= SCREEN_CUT_OFF_WIDTH || screen.availHeight <= SCREEN_CUT_OFF_WIDTH) {
        value = MIN_CELL_SIZE;
    }
    setCSSVariable('--halfCellSize', String(value / 2));
    setCSSVariable('--halfCellSizeInPx', String(value / 2) + px);
    setCSSVariable('--cellSize', String(value));
    setCSSVariable('--cellSizeInPx', String(value) + px);
}

export function setCSSVariable(property, value) {
    document.documentElement.style.setProperty(property, String(value));
}

export async function updateBoard(searchTerm, segments, sentence, isValid, errorMessage, gameBoardClass, graph) {
    await updateGridSize();
    let challenges = await getChallenges(searchTerm.value);
    if (challenges) {
        //convert this to props
        segments.value = challenges?.challengeUniverse?.segments;
        sentence.value = challenges?.sentences[0]?.value;

        graph.value = challenges?.graph;

        isValid.value = challenges?.valid;

        if (!isValid.value) {
            errorMessage.value = challenges?.errorMessage;
        }
        if (segments.value.length > 0) {
            gameBoardClass.value = "game-board";
        } else {
            gameBoardClass.value = "game-board-dark";
        }
    }
}

export async function updateGridSize(hasMenu) {

    updateCellSize();
    let rowAmount = calcRows(hasMenu);
    let colAmount = calcCols();
    const SQUARE_BOARD = false;
    if (SQUARE_BOARD) {
        if (rowAmount < colAmount) {
            colAmount = rowAmount;
        }
        if (colAmount < rowAmount) {
            rowAmount = colAmount;
        }
    }
    setCSSVariable('--rowAmount', rowAmount);
    setCSSVariable('--colAmount', colAmount);
}

function isOdd(someNumber) {
    return someNumber % 2 !== 0;
}

function calcCols() {
    const CELL_SIZE = Number(getCSSVariable("--cellSize"));

    const MARGIN = 20;
    const LEFT = CELL_SIZE + MARGIN;
    let width = calculateWidth(LEFT);
    let cols = Math.ceil(width / CELL_SIZE);
    if (isOdd(cols)) {
        return cols - 1;
    }
    return cols;
}

export function isPortrait() {
    return window.matchMedia("(orientation: portrait)").matches;
}

function calculateWidth(margin) {
    let width = screen.availWidth;
    if (!isPortrait() && isMobile()) {
        width = screen.availHeight;
    }
    return width - margin;
}

function calculateHeight(margin) {
    let height = screen.availHeight
    if (!isPortrait() && isMobile()) {
        height = screen.availWidth;
    }
    return height - margin;
}

function calcRows(hasMenu) {

    const CELL_SIZE = Number(getCSSVariable("--cellSize"));
    const TOP = calculateTopMargin();
    let height = calculateHeight(TOP);
    let rows = Math.ceil(height / CELL_SIZE);
    if (isOdd(rows)) {
        if (hasMenu) {
            if (rows > 7) {
                return rows - 3;
            }
        }
        return rows - 1;
    }
    if (hasMenu) {
        if (rows >= 6) {
            return rows - 2;
        }
    }
    return rows;
}


function calculateTopMargin() {
    const CELL_SIZE = Number(getCSSVariable("--cellSize"));
    let topQuantifier = 3.5;
    if (isMobile()) {
        topQuantifier = 5;
        if (!isPortrait()) {
            topQuantifier = 2.5;
        }
    }
    return topQuantifier * CELL_SIZE;//300 + MARGIN = 140;
}
export function isMobile() {
    return /Android|webOS|iPhone|iPad|iPod|BlackBerry|IEMobile|Opera Mini/i.test(navigator.userAgent);
}


