import HomeView from "@/components/HomeView";
import SwapGameView from "@/components/SwapGameView.vue";
import TestGameView from "@/components/TestView.vue";
import {createApp} from 'vue';
import App from './App.vue';
import {createRouter, createWebHashHistory} from 'vue-router'
import ExploreView from "@/components/ExploreView.vue";
import VueScrollPicker from "vue-scroll-picker";

import "../public/css/vue-scroll-picker.css";
import AboutView from "@/components/AboutView.vue";
import ContactView from "@/components/ContactView.vue";
import {VueReCaptcha} from 'vue-recaptcha-v3'

const routes = [
    { path: '/', name: 'home', component: HomeView },
    {path: '/about', name: 'about', component: AboutView},
    { path: '/swap', name: 'swap', component: SwapGameView, props:true },
    { path: '/test', name: 'test', component: TestGameView, props:true },
    {path: '/explore', name: 'explore', component: ExploreView},
    {path: '/contact', name: 'contact', component: ContactView},
]

// 3. Create the router instance and pass the `routes` option
// You can pass in additional options here, but let's
// keep it simple for now.
// eslint-disable-next-line no-undef
const router = createRouter({
    // 4. Provide the history implementation to use. We are using the hash history for simplicity here.
    // eslint-disable-next-line no-undef
    history: createWebHashHistory(),
    routes, // short for `routes: routes`
})


const app = createApp(App);
// 5. Create and mount the root instance.
// Make sure to _use_ the router instance to make the
// whole app router-aware.
app.use(router)
app.use(VueScrollPicker); // export default is plugin
app.use(VueReCaptcha, {
    siteKey: '6LceO_MpAAAAALpge-qUsfzPHUo_hitCe1pSNPmw',
});

app.mount('#app')
