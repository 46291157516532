<script setup>
import {MESSAGE_STATE, sendMessage} from "@/composables/sendmessage";
import {ref} from "vue";
import {useRouter} from 'vue-router';
import {useReCaptcha} from 'vue-recaptcha-v3';

const {executeRecaptcha} = useReCaptcha();
let sentStatus = ref("start");
let emailIsInvalid = ref(true);
let messageIsEmpty = ref(true);

function validateEmail() {
  let email = document.getElementById("email").value;
  const re =
      /^(([^<>()[\].,;:\s@"]+(\.[^<>()[\].,;:\s@"]+)*)|(".+"))@(([^<>()[\].,;:\s@"]+\.)+[^<>()[\].,;:\s@"]{2,})$/i;
  emailIsInvalid.value = !re.test(email);
}

function validateMessage() {
  let message = document.getElementById("message").value;
  messageIsEmpty.value = message.length < 5;
}

async function sendContact() {
  const token = await executeRecaptcha('contact');
  let email = document.getElementById("email").value;
  let message = document.getElementById("message").value;
  const messageObject = {
    token: token,
    from: email,
    message: message,
  }

  let responseState = await sendMessage(messageObject);

  switch (responseState) {
    case MESSAGE_STATE.SENT:
      sentStatus.value = "success";
      break;
    case MESSAGE_STATE.FAILED:
      sentStatus.value = "failed";
      break;
  }
}

const router = useRouter()


function tryAgain() {
  emailIsInvalid.value = true;
  messageIsEmpty.value = true;
  sentStatus.value = "start";
  router.push({path: '/contact'})
}

</script>

<template>
  <div class="modal-form">
    <div class="form-style">

      <div v-if="sentStatus==='start'">
        <p>
          <input id="email" class="email-style" placeholder="Your email" required @keyup="validateEmail">
        </p>
        <p>
          <textarea id="message" class="text-area-style" placeholder="Your message" required @keyup="validateMessage"/>
        </p>
        <p v-if="!emailIsInvalid && !messageIsEmpty">
          <button class="black-button" role="button" @click="sendContact()">Send</button>
        </p>
        <p v-else>
          <button class="hidden-button">Hidden</button>
        </p>
      </div>
      <div v-else-if="sentStatus==='success'">
        <p>Your message was sent!</p>
        <p>
          <button class="black-button" role="button" @click="tryAgain()">Back</button>
        </p>

      </div>
      <div v-else><p>
        Your message was not sent. Maybe you are a robot?</p>
        <p>
          Go back, check your details and:
        </p>
        <p>
          <button class="black-button" role="button" @click="tryAgain()">Try Again</button>
        </p>
      </div>
    </div>
  </div>


</template>

<style scoped>
textarea, input {
  -webkit-box-sizing: border-box;
  -moz-box-sizing: border-box;
  box-sizing: border-box;
}

.modal-form {
  left: 50%;
  transform: translate(-50%, 0);
  z-index: 20;
  position: absolute;
  background-color: var(--background);
  width: 300px;
  height: 300px;
  text-align: center;
  font-size: 17px;
  border: none;
}

.black-button {
  width: 250px;
}


.email-style, .text-area-style {
  width: 250px;
  background: var(--background);
  color: #FFF;
  font-size: 17px;

  border: 1px #FFF solid;
  padding: 5px;
  height: 48px;
  font-family: 'Roboto Mono', 'monospace';
}

.email-style:focus, .text-area-style:focus {
  outline: none;
  opacity: 0.9;
}

.text-area-style {
  height: 144px;
}

.email-style:focus-visible, .text-area-style:focus-visible {
  outline: none;
}

.form-style {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
}


</style>
