<template>
  <section v-if="isValid">
    <v-network-graph :configs="configs" :edges="graph.edges" :layouts="graph.layouts"
                     :nodes="graph.nodes" style="height: 500px; text-align: center"/>
  </section>
  <section v-else>
    <div class="sentence"> {{ errorMessage }}</div>
  </section>

  <!--
  https://stackoverflow.com/questions/53698837/make-delay-between-item-render-transition-in-array-in-vue-js
  -->

  <br/>

</template>

<script setup>
import {reactive} from "vue";
import * as vNG from "v-network-graph";
import {VNetworkGraph} from "v-network-graph";

defineProps(['isValid', 'errorMessage', "graph"])

const initialConfigs = vNG.defineConfigs({
  node: {
    label: {
      visible: true,
      fontFamily: "monospace",
      fontSize: 17,
      lineHeight: 1.1,
      color: "#FFFFFF",
      margin: 14,
      direction: "west",
      text: "name",
    },
    normal: {
      type: "circle",
      radius: 16,
      strokeWidth: 0,
      strokeColor: "#000000",
      strokeDasharray: "0",
      color: "#CCCCCC",
    },
    hover: {
      color: "#CCCCCC",
      strokeWidth: 0,

    },
  },
  edge: {
    normal: {
      width: 3,
      color: "#CCCCCC",
      dasharray: "0",
      linecap: "butt",
      animate: false,
      animationSpeed: 50,
    },
    hover: {
      color: "#CCCCCC",
      width: 3,
    },
  },
})
const configs = reactive(initialConfigs)

</script>
