<template>
  <div class="game-board-container">
    <PredicateInputView @predicate-input-changed="handlePredicateInputChanged"
                        @show-syntax-changed="handleShowingSyntaxChanged"/>
    <div style="height: 4px"></div>
    <GraphView v-if="showingSyntax" :error-message="errorMessage" :graph="graph" :is-valid="isValid"/>
    <GameBoardView v-else :error-message="errorMessage" :game-board-class="gameBoardClass" :is-valid="isValid"
                   :segments="segments"/>


  </div>
  <br/>

</template>

<script setup>
import {onMounted, ref, watch} from "vue";
import {updateBoard, updateGridSize} from "@/composables/challenges";
import GameBoardView from "@/components/parts/GameBoardView.vue";
import PredicateInputView from "@/components/parts/PredicateInputView.vue";
import GraphView from "@/components/parts/GraphView.vue";

let searchTerm = ref("");
let segments = ref([]);
let sentence = ref("Loading...");
let gameBoardClass = ref("game-board-dark");
let isValid = ref(false);
let errorMessage = ref("");
let graph = ref("");

let showingSyntax = ref(false);

function handlePredicateInputChanged(value) {
  searchTerm.value = value;
}

function handleShowingSyntaxChanged(value) {
  showingSyntax.value = value;
}

onMounted(() => {
  updateGridSize(false);
});


watch(searchTerm, async () => {
  gameBoardClass.value = "game-board-dark";
  const MIN_SEARCH_TERM_CHAR = 5
  if (searchTerm.value.length > MIN_SEARCH_TERM_CHAR) {
    errorMessage.value = "";
    await updateBoard(searchTerm, segments, sentence, isValid, errorMessage, gameBoardClass, graph);
  }
})


function checkOrientation() {
  location.reload();
}

// Set up listeners for orientation change
window.matchMedia('(orientation: portrait)').addEventListener('change', checkOrientation);
window.matchMedia('(orientation: landscape)').addEventListener('change', checkOrientation);


</script>

