<template>
{{timer}}
</template>

<script setup>

import {ref} from "vue";
const emit = defineEmits(['timeIsUp'])

// eslint-disable-next-line no-unused-vars
const props = defineProps({
      secs:{
        type: Number,
        required: true
      }
    }
)

let timer = ref(props.secs);

const interval = setInterval(() => {
  if (timer.value === 0) {
    emit("timeIsUp");
    clearInterval(interval);
  } else {
    timer.value--
  }
}, 1000);


</script>

<style scoped>



</style>