<template>
  <div class="menu-1">
    <div class="menu-input-bar-label">Complexity of Predicate:</div>
    <div class="menu-input-bar">
      <VueScrollPicker class="force-repaint" id="operator-picker" v-model="levelOption" :options="levelOptions"/>
    </div>
    <div class="menu-input-bar-label">Seconds per Predicate:</div>
    <div class="menu-input-bar">
      <VueScrollPicker class="force-repaint" id="operator-picker" v-model="timeOption" :options="timeOptions"/>
    </div>
    <div class="menu-input-bar-label">Number of Predicates:</div>
    <div class="menu-input-bar">
      <VueScrollPicker class="force-repaint" v-if="isMounted" id="operator-picker" v-model="amountOption" :options="amountOptions"/>
    </div>
  </div>

  <br>
  <button class="black-button" @click="start()">Start</button>

  <br>
  <br>
</template>

<script setup>
import {useRouter} from 'vue-router';
import {VueScrollPicker} from "vue-scroll-picker";
import {nextTick, onMounted, ref} from 'vue';

const router = useRouter();

const levelOptions = ["easy", "moderate", "hard", "extreme"];
const timeOptions = ["80", "40", "20", "10"];
const amountOptions = ["5", "10", "20", "40"];
let timeOption;
let amountOption;
let levelOption;

const isMounted = ref(false);

onMounted(() => {
  nextTick(() => {
    isMounted.value = true;
  });
});


function start() {
  router.push({
    name: "test",
    query: {
      complexity: levelOption,
      time: timeOption,
      challengeAmount: amountOption,
      challengeType: "test"
    }
  });
}

</script>
<style>
/* only here so Safari loads css properly */
@keyframes safariRepaintFix {
  from {
    opacity: 1;
  }
  to {
    opacity: 0.99;
  }
}

.force-repaint {
  animation: safariRepaintFix 1s infinite;
}
</style>
