<template xmlns:v-bind=https://vuejs.org/v2/api/#v-bind>
  <div class="segment" v-bind:id="index" @click="enableDrag" v-bind:draggable="setDraggable" @dragover="dragOver"
       @dragenter="handleDragEnter"
       @dragleave="handleDragLeave" @dragstart="start"
       @dragend="end" @drop="drop">
    <div>
      <div v-if="segmentView.hasSVG" class="svg-container">
        <div v-bind:class="segmentView.size">
        <svg class="svg-display" viewBox="-50 -50 100 100">
          <polygon v-bind:points="segmentView.pts" v-bind:class="segmentView.cssClass"/>
          <text dominant-baseline="middle" text-anchor="middle"
                font-size="3em" font-weight="bold" fill="#fff"
          >{{ segmentView.name }}
          </text>
        </svg>
        </div>
      </div>
    </div>
  </div>
</template>

<script setup>
import createSegmentView from "../SegmentViewBuilder.js"
import {ref} from "vue";


const emit = defineEmits(['swapEvent', 'selectedToMoveEvent'])


const props = defineProps({
      segment: {
        type: Object,
        required: true
      },
      index: {
        type: Number,
        required: true
      }
    }
)

const segmentView = createSegmentView(props.segment, props.index);

let setDraggable = ref(false);

function enableDrag(event) {
  emit('selectedToMoveEvent', event.currentTarget.id)

  // setDraggable.value = !setDraggable.value;
  // if (setDraggable.value) {
  //   for (let i = 0; i < 64; i++) {
  //     if(event.currentTarget.id != i) {
  //       document.getElementById(i).className = "notDraggable";
  //     }
  //   }
  //   event.currentTarget.className = "draggable";
  // } else {
  //   event.currentTarget.className = "notDraggable";
  // }
}

function swap(event, firstId, secondId) {
  const pairs = {}
  pairs.firstId = Number(firstId);
  pairs.secondId = Number(secondId);
  emit('swapEvent', pairs)
}


function handleDragEnter() {
  //not implemented
}

function handleDragLeave(event) {
  event.currentTarget.style.backgroundColor = getComputedStyle(document.documentElement)
      .getPropertyValue('--background');
}

function start(event) {
  event.dataTransfer.effectAllowed = 'move';
  event.target.style.opacity = '0.4';
  event.dataTransfer.setData("draggedId", event.target.id);
}

function end(event) {
  event.target.style.opacity = '1';
  event.currentTarget.style.backgroundColor = getComputedStyle(document.documentElement)
      .getPropertyValue('--background');
}

function dragOver(event) {
  event.preventDefault();
  return false;
}

function drop(event) {
  event.stopPropagation();

  //get dragged html
  const draggedId = event.dataTransfer.getData("draggedId")
  const draggedElement = document.getElementById(draggedId);
  const draggedHTML = draggedElement.innerHTML;

  // overwrite dragged with current
  draggedElement.innerHTML = event.currentTarget.innerHTML;

  // overwrite current with dragged
  event.currentTarget.innerHTML = draggedHTML

  // remove styles
  event.currentTarget.style.opacity = '1';
  draggedElement.style.opacity = '1';
  draggedElement.style.backgroundColor = getComputedStyle(document.documentElement)
      .getPropertyValue('--background');
  event.currentTarget.style.backgroundColor = getComputedStyle(document.documentElement)
      .getPropertyValue('--background');
  swap(draggedId, event.currentTarget.id, draggedId);

  return false;
}

</script>

<style scoped>

.segment {
}

.segment:hover {
  /*cursor: move; !* fallback if grab cursor is unsupported *!*/
  /*cursor: grab;*/
  /*cursor: -moz-grab;*/
  /*cursor: -webkit-grab;*/
}

.draggable {
  cursor: move; /* fallback if grab cursor is unsupported */
  cursor: grab;
  background-color: #FFFFFF;
  cursor: -moz-grab;
  cursor: -webkit-grab;
}

.notDraggable {
  background-color: var(--background);
}

.svg-container {
  align-items: center;
  height: 100%;
}

.svg-display {
  margin: 0 auto;
  display: block;
}

.small{
  width: 40%;
  -webkit-transform: translate(70%, 70%);
  transform: translate(70%, 70%);
}

.medium{
    width: 75%;
    -webkit-transform: translate(15%, 15%);
    transform: translate(15%, 15%);
}

.large{
  width: 100%;
}
</style>
