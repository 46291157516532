
export default function createSegmentView(segment, index) {
    const segmentView = {};
    segmentView.id = index;
    segmentView.index = segment.index;
    segmentView.size = createSize(segment);
    segmentView.cssClass = createClass(segment);
    segmentView.pts = createPts(segment);
    segmentView.hasSVG = createHasSVG(segment);
    segmentView.name = segment.name;
    return segmentView;
}

function createHasSVG(segment) {
    return segment.color != null;
}

function createClass(segment) {
    let cssClass;
    switch (segment.color) {
        case "RED": {
            cssClass = "red";
            break;
        }
        case "YELLOW": {
            cssClass = "yellow";
            break;
        }
        case "GREEN": {
            cssClass = "green";
            break;
        }
        case "GREENISH": {
            cssClass = "greenish";
            break;
        }
        case "GREY": {
            cssClass = "grayish";
            break;
        }
        case "BLUE": {
            cssClass = "blue";
            break;
        }
        default: {
            cssClass = "none";
            break;
        }
    }
    return cssClass;
}

function createSize(segment) {
    let size = 0;
    switch (segment?.size?.size) {
        case 1: {
            size = "small";
            break;
        }
        case 2 : {
            size = "medium";
            break;
        }
        default: {
            size = "large";
            break;
        }
    }
    return size;
}


function createPts(segment) {
    let points;
    switch (segment.shape) {
        case "TRIANGLE": {
            points = "-40,35 0,-35 40,35";
            break;
        }
        case "SQUARE" : {
            points = "-35,35 35,35 35,-35, -35,-35";
            break;
        }
        case "HEXAGON" : {
            points = "-20,36    -40,0    -20,   -36    20   ,-36    40   ,0 20,36";
            break;
        }
        default: {
            points = "0,0 0,0 0,0 0,0";
            break;
        }
    }
    return points;
}
