<template>
  <div class="game-board-container">
    <section class="sentence">
      <sentence-explanation v-for="(element, index) in elementMap"
                            :key=element
                            :elementMap=element
                            :index=index
      />
    </section>
    <section class="game-board">
      <segment-view v-for="(seg, index) in segments"
                   :key=seg
                   :index=index
                   :segment=seg
      />
    </section>
    <div style="margin-top: 5px"></div>
    <div class="menu">

      <div v-if="state==='review'" class="menu-item-button" role="button" @click="back()">
        <img :src="backSvg" alt="back" height="17px"/>
      </div>
      <div v-else class="menu-item-label">
      </div>

      <div class="menu-item">
        {{ currentChallenge + 1 }}/{{ amountFixed }}
      </div>

      <div class="menu-item">
        <TimerView v-if="state==='play'" @timeIsUp="timeIsUp" :secs=startSeconds :key="sentence"></TimerView>
        <template v-else>
          {{ finalScore }}%
        </template>
      </div>

      <div class="menu-item-button" role="button" @click="next()">
        <img :src="nextSvg" alt="next"
             height="17px"/>
      </div>

    </div>

    <div v-if="state==='pre-review'">
      <div class="modal">
        <br/>
        <br/>
        <br/>
        <p>
          Your score was {{ finalScore }}%.
        </p>
        <p>
          <button class="black-button" role="button" @click="startReview()">Review</button>
        </p>
        <p>
          Or
        </p>
        <p>
          <button class="black-button" role="button" @click="home()">Play Again</button>
        </p>
      </div>

    </div>
    <!-- Review Menu -->
    <div class="menu" v-if="state==='review'">

      <div class="menu-item">
          <img v-if="isCorrect()" :src="correctSvg" alt="correct"
               height="24px"
               style="margin-top: 1px; display: block; margin-left: auto; margin-right: auto;"/>

          <img v-if="!isCorrect()" :src="incorrectSvg" alt="incorrect"
               height="24px"
               style="margin-top: 2px; margin-left: auto; margin-right: auto; display: block;"/>
      </div>

      <template v-if="getAnswer()==='-'">

        <div class="menu-item-button-blurred">
          T
        </div>
        <div class="menu-item-button-blurred">
          F
        </div>
      </template>
      <template v-else>

        <template v-if="ifAnsweredWasTrue()">
          <div class="menu-item-selected">
            T
          </div>
          <div class="menu-item-button-blurred">
            F
          </div>
        </template>
        <template v-if="ifAnsweredWasFalse()">
          <div class="menu-item-button-blurred">
            T
          </div>
          <div class="menu-item-selected">
            F
          </div>
        </template>
      </template>


      <div class="menu-item-button" role="button" @click="home()">

        <img :src="homeSvg" alt="home"
             height="17px"/>
      </div>

    </div>

    <!-- Playing Menu -->
    <div class="menu" v-if="state==='play'">

      <div class="menu-item-label">&nbsp;</div>

      <div class="menu-item-button" role="button" style="color: var(--background);" @click="answer(true)">
        T
      </div>

      <div class="menu-item-button" style="color: var(--background);" @click="answer(false)">
        F
      </div>

      <div class="menu-item-label">&nbsp;</div>

    </div>
    <br/>
  </div>

</template>

<script setup>
import SegmentView from "@/components/SegmentView";
import TimerView from "@/components/TimerView";
import axios from 'axios';
import {onMounted, ref} from "vue";
import backSvg from '@/assets/back.svg';
import nextSvg from '@/assets/next.svg';
import homeSvg from '@/assets/home.svg';
import correctSvg from '@/assets/correct.svg';
import incorrectSvg from '@/assets/incorrect.svg';
import {useRoute, useRouter} from "vue-router";
import {getCSSVariable, updateGridSize} from "@/composables/challenges";
import SentenceExplanation from "@/components/parts/SentenceExplanation.vue";


let segments = ref([]);
let sentence = ref("Loading...");
let elementMap = ref(null);
let isLoaded = ref(false);
let startSeconds = ref(null);
let score = ref(0);
let solution = ref("");
let challengeList = ref([]);
let currentChallenge = ref(0);
let time = ref();
let state = ref("play");
let challengeAmount = 0;
const answers = [];
let amountFixed = ref(0);
let finalScore = 0;


const router = useRouter();


onMounted(async () => {
  //this should be done in HomeView
  const route = useRoute();
  await router.isReady();
  startSeconds.value = Number.parseInt(route.query.time);
  time.value = route.query.time;
  const challengeType = route.query.challengeType;
  const complexity = route.query.complexity;
  challengeAmount = route.query.challengeAmount;

  document.documentElement.style.setProperty('--rowAmount', "8");
  document.documentElement.style.setProperty('--colAmount', "8");
  let challenges = await getChallenges(challengeType, challengeAmount, complexity);
  if (challenges) {
    //convert this to props
    challengeList.value = challenges;
    setChallenge();
    amountFixed.value = Number.parseInt(challengeAmount);
  }
})

function isCorrect() {
  return answers[currentChallenge.value] === solution.value;
}

function getAnswer() {
  let ans = answers[currentChallenge.value];
  if (ans != null) {
    return ans;
  }
  return "-";
}

function ifAnsweredWasTrue() {
  return (getAnswer() === true);
}

function ifAnsweredWasFalse() {
  return getAnswer() === false;
}

function startReview() {
  currentChallenge.value = 0;
  setChallenge();
  state.value = "review";
}

function back() {
  const INDEX_START = 0;
  if (currentChallenge.value > INDEX_START) {
    currentChallenge.value--;
    setChallenge();
  }
}

function setChallenge() {
  isLoaded.value = true;
  const currentChal = challengeList.value[currentChallenge.value];
  segments.value = currentChal.challengeUniverse.segments;
  const currentSent = challengeList.value[currentChallenge.value].sentences[0];
  sentence.value = currentSent.value;
  solution.value = currentSent.evaluation;
  elementMap.value = currentSent.elementMap;
}

function next() {
  const INDEX_END = challengeAmount - 1;
  if (currentChallenge.value < INDEX_END) {
    currentChallenge.value++;
    if (state.value === "play") {
      startSeconds.value = Number.parseInt(time.value);
    }
    setChallenge();
  } else {
    state.value = "pre-review";
    time.value = 0;
  }
}

function timeIsUp() {
  sentence.value = "Time is up...";
  time.value = 0;
  startSeconds.value = 0;
  state.value = "pre-review";
}

function home() {
  router.push({name: 'home'});
}

function answer(answer) {
  answers[currentChallenge.value] = answer;
  tallyScore();
  next();
}

function tallyScore() {
  if (challengeList.value[currentChallenge.value].sentences[0].evaluation === answers[currentChallenge.value]) {
    score.value++;
    finalScore = (Math.round((score.value / challengeAmount) * 100));
  }
}

async function getChallenges(challengeType, challengeAmount, complexity) {
  const hasMenu = true
  await updateGridSize(hasMenu);

  let rowAmount = getCSSVariable("--rowAmount");
  let colAmount = getCSSVariable("--colAmount");
  const url = `/challenge?type=${challengeType}&amount=${challengeAmount}&complexity=${complexity}&rowAmount=${rowAmount}&colAmount=${colAmount}`;
  try {

    const response = await axios.get(url);
    return response.data;
  } catch (err) {
    console.log(err);
  }
}

function checkOrientation() {
  location.reload();
}

// Set up listeners for orientation change
window.matchMedia('(orientation: portrait)').addEventListener('change', checkOrientation);
window.matchMedia('(orientation: landscape)').addEventListener('change', checkOrientation);


</script>
