import axios from "axios";

export const MESSAGE_STATE = {
    SENT: "sent",
    FAILED: "failed",
}


export async function sendMessage(message) {
    let response = new Response();
    const url = "/contact";
    try {
        response = await axios.post(url, message, {
            headers: {
                'Content-Type': 'application/json'
            }
        });
        if (response.status === 200) {
            return MESSAGE_STATE.SENT;
        }
        return MESSAGE_STATE.FAILED;
    } catch (err) {
        return MESSAGE_STATE.FAILED;
    }
}
