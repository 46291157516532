<template>
  <div class="predicate-input-bar">
    <div class="predicate-input-bar-1">
      <input
          id="predicateInput"
          v-model="inputValue"
          class="predicate-input-full"
          placeholder="Type a predicate here..."
          type="text"
          :disabled="!enableGeneration"
      ></div>
    <div id="input-menu" class="subgrid">
    <div class="predicate-input-bar-2">
      <VueScrollPicker id="operator-picker" :options="operators"/>
    </div>
    <div class="predicate-input-bar-3">
      <VueScrollPicker id="predicates-picker" :options="predicates"/>
    </div>
    <div class="predicate-input-bar-3" style="justify-content: center;
    align-items: center;">
      <div class="generate-menu">
        <div style="display:flex;justify-content: center;border-right: 1px #FFF solid">
          <img v-if="enableGeneration" :src="genSvg" alt="generate" class="pred-gen-img" title="Generate a predicate"
               @click="randomSentence()"/>
          <img v-else :src="penSvg" alt="pending" title="pending"
               height="24px"/>
        </div>
        <div style="display:flex;justify-content: center">
          <img v-if="showingSyntax" :src="hexSvg" alt="show syntax" class="pred-gen-img" title="Show syntax tree"
               @click="showHex()"/>
          <img v-else :src="graphSvg" alt="show visualization" class="pred-gen-img" title="Show visualization"
               @click="showSyntax()"/>
        </div>
      </div>
    </div>
    </div>
  </div>
</template>

<script setup>
import {VueScrollPicker} from "vue-scroll-picker";
import {onMounted, ref, watch} from "vue";
import genSvg from '@/assets/generate.svg';
import penSvg from '@/assets/pending.svg';
import graphSvg from '@/assets/graph.svg';
import hexSvg from '@/assets/hex.svg';

import {getRandomSentence} from "@/composables/challenges";

const emit = defineEmits(["predicateInputChanged", "showSyntaxChanged"]);

const showingSyntax = ref(false);

const inputValue = ref('');
const enableGeneration = ref(true);

function sendSearchTerm() {
  emit("predicateInputChanged", inputValue.value);
}

const operators = ["∀", "∃", "∈", "∉", "→", "⊼", "⊽", "∨", "∧", "↔", "⊕", "¬", "(", ")"];
const predicates = ["Red", "Yellow", "Grey", "Green", "Blue", "Triangle", "Square", "Hexagon", "Small", "Medium", "Large", "Smaller", "Larger", "SameSize", "Equals", "NotEquals", "Bottom", "Top", "Right", "Left", "SameCol", "SameRow", "LeftOf", "Above", "Below", "Between"];
const predicateMap = new Map();

predicateMap.set("Red", "Red(x)");
predicateMap.set("Yellow", "Yellow(x)");
predicateMap.set("Grey", "Grey(x)");
predicateMap.set("Green", "Green(x)");
predicateMap.set("Blue", "Blue(x)");

predicateMap.set("Triangle", "Triangle(x)");
predicateMap.set("Square", "Square(x)");
predicateMap.set("Hexagon", "Hexagon(x)");

predicateMap.set("Small", "Small(x)");
predicateMap.set("Medium", "Medium(x)");
predicateMap.set("Large", "Large(x)");

predicateMap.set("Smaller", "Smaller(x, y)");
predicateMap.set("Larger", "Larger(x, y)");
predicateMap.set("SameSize", "SameSize(x, y)");

predicateMap.set("Equals", "Equals(x, y)");
predicateMap.set("NotEquals", "NotEquals(x, y)");

predicateMap.set("Bottom", "Bottom(x)");
predicateMap.set("Top", "Top(x)");
predicateMap.set("Right", "Right(x)");
predicateMap.set("Left", "Left(x)");

predicateMap.set("SameCol", "SameCol(x, y)");
predicateMap.set("SameRow", "SameRow(x, y)");
predicateMap.set("LeftOf", "LeftOf(x, y)");
predicateMap.set("Above", "Above(x, y)");
predicateMap.set("Below", "Below(x, y)");
predicateMap.set("Between", "Between(x, y, z)");
predicateMap.set("MirrorCol", "MirrorCol(x, y)");

predicateMap.set("∀", "∀x");
predicateMap.set("∃", "∃x");
predicateMap.set("∈", "∈");
predicateMap.set("∉", "∉");
predicateMap.set("→", "→");
predicateMap.set("⊼", "⊼");
predicateMap.set("⊽", "⊽");
predicateMap.set("∨", "∨");
predicateMap.set("∧", "∧");
predicateMap.set("↔", "↔");
predicateMap.set("⊕", "⊕");
predicateMap.set("¬", "¬");
predicateMap.set("(", "(");
predicateMap.set(")", ")");



onMounted(() => {
  let predicatesPicker = document.getElementById('predicates-picker');
  let operatorPicker = document.getElementById('operator-picker');

  operatorPicker.addEventListener("touchstart", handleTouchStart, {passive: true});
  operatorPicker.addEventListener("touchend", handleTouchEnd);
  operatorPicker.addEventListener('click', addPredElement);

  predicatesPicker.addEventListener("touchstart", handleTouchStart, {passive: true});
  predicatesPicker.addEventListener("touchend", handleTouchEnd);
  predicatesPicker.addEventListener('click', addPredElement);
});


//https://stackoverflow.com/questions/20225153/preventing-ghost-click-when-binding-touchstart-and-click
// https://stackoverflow.com/questions/24077725/mobile-safari-sometimes-does-not-trigger-the-click-event

let startX, startY, startTime;

function handleTouchStart(event) {
  startX = event.touches[0].clientX;
  startY = event.touches[0].clientY;
  startTime = new Date().getTime();
}

function handleTouchEnd(event) {

  const endX = event.changedTouches[0].clientX;
  const endY = event.changedTouches[0].clientY;
  const endTime = new Date().getTime();

  const deltaX = endX - startX;
  const deltaY = endY - startY;
  const deltaTime = endTime - startTime;

  // Set a threshold for movement and time to consider it a click
  const movementThreshold = 10; // pixels
  const timeThreshold = 200; // milliseconds

  if (Math.abs(deltaX) < movementThreshold && Math.abs(deltaY) < movementThreshold && deltaTime < timeThreshold) {
    let predElement = retrievePredValue(event);
    addPredElement(predElement);
  }
}

function showSyntax() {
  showingSyntax.value = true
  emit("showSyntaxChanged", showingSyntax.value);
}

function showHex() {
  showingSyntax.value = false;
  emit("showSyntaxChanged", showingSyntax.value);
}

function retrievePredValue(predElement) {
  const valueClass = "vue-scroll-picker-item-selected";
  const predicate = predElement.target.parentNode.parentNode.getElementsByClassName(valueClass)[0].innerHTML;
  return predicateMap.get(predicate);

}


function addPredElement(predElement) {
  if (predElement instanceof PointerEvent) {
    predElement = retrievePredValue(predElement);
  }
  let predInput = document.getElementById("predicateInput");

  if (predInput.selectionStart === 0 || predInput.selectionStart === '0') {
    let startPos = predInput.selectionStart;
    let endPos = predInput.selectionEnd;
    inputValue.value = inputValue.value.substring(0, startPos)
        + predElement + " "
        + inputValue.value.substring(endPos, inputValue.value.length);
  } else {
    inputValue.value += predElement + " ";
  }
}


async function randomSentence() {
  enableGeneration.value = false;
  let predInput = document.getElementById("predicateInput");
  predInput.placeholder = "Generating a random predicate..."
  let sentence = await getRandomSentence();
  const MIN_SENTENCE_LENGTH = 3;
  if (typeof sentence === "string" && sentence.length > MIN_SENTENCE_LENGTH) {
    inputValue.value = sentence;
    enableGeneration.value = true;
    predInput.placeholder = "Explore a predicate here...";

  }
}

watch(inputValue, async () => {
  sendSearchTerm();
})
</script>

<style scoped>

.generate-menu {
  display: grid;
  grid-template-columns: repeat(2, [col] var(--halfCellSizeInPx));
  justify-content: center;
}
</style>
