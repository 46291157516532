<script setup>

</script>

<template>
  <div id="about-text">
    <p></p>
    <p>Inspired by <a href="https://en.wikipedia.org/wiki/Tarski%27s_World" target="_blank">"Tarski’s World"</a>,
      Tarski is an app designed to explore and practice with logical predicate statements in a visual way.</p>
    <p>There are two modes in Tarski:</p>
    <ul>
      <li>
        <router-link to="/explore">Explore mode</router-link>
      </li>
      <li>
        <router-link to="/">Practice mode</router-link>
      </li>
    </ul>
    <p>In
      <router-link to="/explore">Explore mode</router-link>
      , the app generates a world of objects corresponding with a given predicate.
      You can switch the view to either show the syntax tree or a visual interpretation of the predicate.
    </p>
    <p>In
      <router-link to="/">Practice mode</router-link>
      , you can create a predicate practice set. Random predicates are generated along with corresponding worlds
      of objects, and your task is to determine if the predicate is true or false given the world.
    </p>
  </div>
</template>
